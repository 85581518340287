var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change the color of the loading with the property "),
        _c("code", [_vm._v("color")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment mt-3" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colorLoading,
                expression: "colorLoading",
              },
            ],
            attrs: { type: "color", name: "", value: "" },
            domProps: { value: _vm.colorLoading },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colorLoading = $event.target.value
              },
            },
          }),
          _c(
            "vs-button",
            {
              attrs: { type: "filled", color: _vm.colorLoading },
              on: { click: _vm.openLoadingColor },
            },
            [_vm._v("Loading")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <div class="demo-alignment">\n\n    <input type="color" v-model="colorLoading" name="" value="">\n    <vs-button @click="openLoadingColor" type="filled" :color="colorLoading">Loading</vs-button>\n\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      colorLoading: \'#ff8000\',\n    }\n  },\n  methods: {\n    openLoadingColor() {\n      this.$vs.loading({ color: this.colorLoading })\n      setTimeout(() => {\n        this.$vs.loading.close()\n      }, 2000);\n    },\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }