var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Contained", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a loading within a container, call the "),
        _c("code", [_vm._v("$vs.loading()")]),
        _vm._v(" global function passing the "),
        _c("code", [_vm._v("container")]),
        _vm._v(" and the "),
        _c("code", [_vm._v("scale")]),
        _vm._v(" parameters. When you want to remove it we have "),
        _c("code", [_vm._v("$vs.loading.close()")]),
        _vm._v(", passing the same "),
        _c("code", [_vm._v("container")]),
        _vm._v(" parameter"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v(
              "For the examples, the request or the delay is simulated with "
            ),
            _c("code", [_vm._v("setTimeout")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment mt-3" },
        [
          _c(
            "vs-button",
            {
              ref: "loadableButton",
              staticClass: "vs-con-loading__container",
              attrs: { id: "button-with-loading", type: "relief" },
              on: { click: _vm.openLoadingContained },
            },
            [_vm._v("\n        Button with Loading\n      ")]
          ),
          _c(
            "vs-button",
            { attrs: { type: "relief" }, on: { click: _vm.openLoadingInDiv } },
            [_vm._v("Div with Loading")]
          ),
          _c("div", { staticClass: "contained-example-container" }, [
            _c(
              "div",
              {
                staticClass: "vs-con-loading__container",
                attrs: { id: "div-with-loading" },
              },
              [_vm._v("Load Me!")]
            ),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-button ref="loadableButton" id="button-with-loading" class="vs-con-loading__container" @click="openLoadingContained" type="relief">\n    Button with Loading\n  </vs-button>\n  <vs-button @click="openLoadingInDiv" type="relief">Div with Loading</vs-button>\n\n  <div class="contained-example-container">\n    <div id="div-with-loading" class="vs-con-loading__container">Load Me!</div>\n  </div>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      backgroundLoading:\'primary\',\n      colorLoading:\'#fff\',\n    }\n  },\n  methods:{\n    openLoadingContained(){\n      this.$vs.loading({\n        background: this.backgroundLoading,\n        color: this.colorLoading,\n        container: "#button-with-loading",\n        scale: 0.45\n      })\n      setTimeout( ()=> {\n        this.$vs.loading.close("#button-with-loading > .con-vs-loading")\n      }, 3000);\n    },\n    openLoadingInDiv(){\n      this.$vs.loading({\n        container: \'#div-with-loading\',\n        scale: 0.6\n      })\n      setTimeout( ()=> {\n        this.$vs.loading.close(\'#div-with-loading > .con-vs-loading\')\n      }, 3000);\n    },\n  }\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }